<template>
  <td class="text-body-1 " :style="{textAlign: header.align ? header.align : 'center'}">
    <v-layout column>
        <DefaultLabel>{{ item.title }}</DefaultLabel>
        <DefaultSubText color="secondary" class="pre-wrap">{{ item.text }}</DefaultSubText>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined
    },
    item: {
      default: undefined
    },
    value: {}
  },
  components: {
      DefaultLabel: () => import("@/components/text/DefaultLabel"),
      DefaultSubText: () => import("@/components/text/DefaultSubText"),
  },
};
</script>

<style scoped lang="css">
.pre-wrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
